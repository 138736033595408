import { createAppContainer } from 'react-navigation';
import { createSidebarNavigator } from '../tabs';

import VocabularyNavigator from '../navigation/VocabularyNavigator';
import HSKNavigator from '../navigation/HSKNavigator';
import AffiliateNavigator from './AffiliateNavigator';
import AccountNavigator from './AccountNavigator';
import AuthNavigator from './AuthNavigator';
import LinkGame from '../components/LinkGame';
import Logout from '../components/Logout';
import Register from '../components/Register';
import AdvancedNavigator from './AdvancedNavigator';

import CartNavigator from './CartNavigator';
import BookNavigator from './BookNavigator';
import ChatNavigator from './ChatNavigator';
import EmailForm from '../components/EmailForm';
import SampleNavigator from './SampleNavigator';
import PlanNavigator from './PlanNavigator';

const SidebarNavigator = createSidebarNavigator(
  {
    Sample: {
      screen: SampleNavigator,
      params: {
          icon: 'chat',
          tabName: 'Sample Dialog',
      }
    },
    Vocabulary: {
      screen: VocabularyNavigator,
      params: {
          icon: 'file-word-box-outline',
          tabName: 'Vocabulary',
      }
    },
    HSK: {
      screen: HSKNavigator,
        params: {
            icon: 'alpha-h-box',
            tabName: 'HSK Courses',
        }
    },
    Plan: {
      screen: PlanNavigator,
        params: {
            icon: 'wallet-membership',
            tabName: 'Pricing',
        }
    },
    Affiliate: {
      screen: AffiliateNavigator,
        params: {
            icon: 'currency-usd',
            tabName: 'Earn Credit',
        }
    },
    Advanced: {
      screen: AdvancedNavigator,
        params: {
            icon: 'panda',
            tabName: 'Culture',
        }
    },
    Book: {
      screen: BookNavigator,
        params: {
          icon: 'book',
          tabName: 'Audio Book',
        }
    },
    // Shop: {
    //   screen: ShopNavigator,
    //     params: {
    //         icon: 'shopping-outline',
    //         tabName: 'Shop',
    //     }
    // },
    Game: {
      screen: LinkGame,
        params: {
            icon: 'gamepad-variant-outline',
            tabName: 'Game',
        }
    },
    // Chat: {
    //   screen: ChatNavigator,
    //   params: {
    //       icon: 'chat',
    //       tabName: 'Chat',
    //   }
    // },
    Contact: {
      screen: EmailForm,
      params: {
          icon: 'email',
          tabName: 'Contact',
      }
    },
    //below is for top menu
    Account: {
      screen: AccountNavigator,
      params: {
          icon: 'email',
          tabName: 'Account',
      }
    },
    Login: {
      screen: AuthNavigator,
      params: {
          icon: 'email',
          tabName: 'Login',
      }
    },
    Logout: {
      screen: Logout,
      params: {
          icon: 'email',
          tabName: 'Logout',
      }
    },
    Register: {
      screen: Register,
      params: {
          icon: 'email',
          tabName: 'Register',
      }
    },
    Cart: {
      screen: CartNavigator,
      params: {
          icon: 'email',
          tabName: 'ShoppingCart',
      }
    },
  },
  {
    initialRouteName: 'Sample',
  },
);

export default createAppContainer(SidebarNavigator);