import { useEffect, useState } from "react";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";

import api from '../api/client';
import useAuth from "../hooks/useAuth";

const style = {"layout":"vertical"};

export const ButtonWrapper = ({ id, amount, type, coupon, credit, showSpinner, onPaid }) => {

    const [{ options, isPending }, dispatch] = usePayPalScriptReducer();

    const [orderID, setOrderID] = useState(id == undefined? null : id);

    const { user, setUser } = useAuth();

    const createOrder = async (paypalID) => {
        if (orderID != null)
            return;
        
        let newCoupon = coupon == undefined? null : coupon;
        let newCredit = credit == undefined? 0 : credit;

        const { data } = await api.put('/Order/Member/' + user.personID + '/' + amount + '/' + type + '/' + paypalID + '/' + newCoupon + '/' + newCredit);
        setOrderID(data);

        let c = user.credit - newCredit;
        let u = { ...user, credit: c };
        setUser(u);
    }

    const updateOrder = async () => {
        const { data: date } = await api.put('/Order/Member/' + orderID + '/' + user.personID + '/' + type);
        onPaid(date);
    }

    useEffect(() => {
        dispatch({
            type: "resetOptions",
            value: {...options},
        });
    }, [showSpinner]);

    return (<>
            { (showSpinner && isPending) && <div className="spinner" /> }
            <PayPalButtons
                style={style}
                disabled={false}
                forceReRender={[amount, 'USD', style]}
                fundingSource={undefined}
                createOrder={(data, actions) => {
                    return actions.order
                        .create({
                            purchase_units: [
                                {
                                    amount: {
                                        currency_code: "USD",
                                        value: amount,
                                    },
                                },
                            ],
                            application_context: {
                                shipping_preference: "NO_SHIPPING"
                            }
                        })
                        .then((orderId) => {
                            createOrder(orderId);
                            return orderId;
                        });
                }}
                onApprove={(data, actions) => {
                    return actions.order.capture().then(function () {
                        updateOrder();
                    });
                }}
            />
        </>
    );
}